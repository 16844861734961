import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createDocument,
  deleteDocument,
  getCollection,
  getDocument,
  snapshot,
  timestamp,
  fieldDelete,
  updateDocumentRef
} from "../config";
import configureStore from "../configureStore";
import moment from "moment";

export const getAccounts = createAsyncThunk(
  "settingAccount/getAccounts",
  async (_,{getState,rejectWithValue}) => {
    try{
      let {account} = getState();
      let {user} = account;
      let rs = await getCollection("user_accounts", {
        where: [
          ["userID", "==", user.id],
          ["accountType.stripe", "==", true],
        ],
      });
      return rs;
    }catch(e){
      return rejectWithValue(e.message);
    } 
  }
);

export const getLocations = createAsyncThunk(
  "settingAccount/getLocations",
  async (_,{getState,rejectWithValue}) => {
    try{
      let {account} = getState();
      let {user} = account;
      let rs = await getCollection("locations", {
        where: [["users." + user.id, "==", true]],
      });
      return rs;
    }catch(e){
      return rejectWithValue(e.message);
    } 
  }
);

export const getSubscription = createAsyncThunk(
  "settingAccount/getSubscription",
  async (_,{getState,rejectWithValue}) => {
    try{
      let {account} = getState();
      let {user} = account;
      let rs = await getDocument("user_subscriptions", user.id);
      return rs;
    }catch(e){
      return rejectWithValue(e.message);
    } 
  }
);

export const loadSecrets = createAsyncThunk(
  "settingAccount/loadSecrets",
  async (_,{getState,rejectWithValue,dispatch}) => {
    try{
      let {account} = getState();
      let {user,subscription} = account;
      await createDocument({
        entity:'user_secrets',
        id:user.id,
        merge:true
      },{
        action: "plaid",
        updated: timestamp(),
      });
      let listener = snapshot('user_secrets',user.id,(doc)=>{
          let data = doc.data();
          if (data.plaid) {
            listener();
            if (data.plaid.link_token) {
              if (moment(data.plaid.expiration).isBefore(moment())) {
                dispatch(setLoadingSecrets(false));
                dispatch(setLoadingError('Plaid token has expired.'));
                updateDocumentRef(doc.ref,{plaid: fieldDelete()});
              } else {
                dispatch(setPlaidLinkToken(data.plaid.link_token));
                if (typeof window !== "undefined") {
                  window.linkHandler = window.Plaid.create({
                    token: data.plaid.link_token,
                    onExit: function (err, metadata) {
                      if (err != null) {
                        console.log(err)
                      }
                    },
                    onSuccess: function (public_token, metadata) {
                      let accountID = metadata.account.id;
                      delete metadata.account.id;
                      let data = {
                        accountType: { stripe: true },
                        publicToken: public_token,
                        userID: user.id,
                        institution: metadata.institution,
                        accountID:accountID,
                        ...metadata.account,
                      };
                      getCollection("user_accounts", {
                        where: [
                          ["userID", "==", user.id],
                          ["accountType.stripe", "==", true],
                        ],
                      }).then((accounts) => {
                        if (accounts.length == 0) data.default = true;
                        return createDocument({ entity: "user_accounts" }, data);
                      }).then((ref) => {
                          dispatch(getAccounts());
                          if (
                            account.currentPlan == "corporate" &&
                            !account.subscription.stripeSubscription
                          ) {
                            dispatch(
                              setSubscriptions({
                                ...subscription,
                                id: user.id,
                                stripeSubscription: {
                                  status: "active",
                                },
                              })
                            );
                            dispatch(setUpdated(true));
                          }
                        })
                        .catch((err) => {
                          console.log("err", err);
                        });
                    },
                    product: ["auth"],
                  });
                  dispatch(setLoadingSecrets(false));
                }
              }
            }
          }
      },{includeMetadataChanges:true});
      return;
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);    

export const cancelSubscription = createAsyncThunk(
  "settingAccount/cancelSubscription",
  async (user) => {
    return createDocument(
      { entity: "user_subscriptions", id: user.id, merge: true },
      { cancel: true }
    );
  }
);

export const reactivateSubscription = createAsyncThunk(
  "settingAccount/reactivateSubscription",
  async (_, { getState, dispatch }) => {
    const { settingsAccount, account } = getState();
    const {user} = account;
    const { currentPlan, subscription } = settingsAccount;
    let subs = subscription;
    if (currentPlan == "standard") {
      subs.type = "automatic";
      Object.keys(subs.brands).forEach((b) => (subs.brands[b].price = 49.99));
    }
    subs = Object.assign(
      {},
      { ...subs },
      {
        plan: currentPlan,
        starts: moment().format("YYYY-MM-DD"),
        updated: true,
      }
    );
    dispatch(setSubscriptions(subs));
    return createDocument(
      {
        entity: "user_subscriptions",
        id: user.id,
        merge: true,
      },
      subs
    );
  }
);

export const removeAccount = createAsyncThunk(
  "settingsAccount/removeAccount",
  async (_, { getState, rejectWithValue }) => {
    const { settingsAccount, account } = getState();
    const { selectedAccount } = settingsAccount;
    try {
      await deleteDocument("user_accounts", selectedAccount.id);
      return;
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const settingAccountSlice = createSlice({
  name: "settingAccount",
  initialState: {
    loading: false,
    loadingError: null,
    loadingAccounts: true,
    loadingLocations: true,
    loadingSubs: true,
    loadingSecrets: false,
    cancelled: null,
    updated: null,
    accounts: [],
    plaidLinkToken: null,
    locations: {},
    subscription: { brands: [] },
    plan: {
      locations: 0,
      price: 0,
    },
    existingPlan: "starter",
    currentPlan: "starter",
    stripe: null,
    elements: null,
    cancelling: false,
    showCancelModal: false,
    updating: false,
    savingCard: false,
    showRemoveAccountModal: false,
    selectedAccount: null,
    deleting: false,
    deletingError: null,
    showChangeDefaultModal: false,
    showChangeDefaultPaymentsModal: false,
    showTerms: false,
    saving: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingSecrets: (state, action) => {
      state.loadingSecrets = action.payload;
    }, 
    setLoadingError: (state, action) => {
      state.loadingError = action.payload;
    },
    setStripe: (state, action) => {
      state.stripe = action.payload;
    },
    setElements: (state, action) => {
      state.elements = action.payload;
    },
    setPlaidLinkToken: (state, action) => {
      state.plaidLinkToken = action.payload;
    },
    setUpdated: (state, action) => {
      state.updated = action.payload;
    },
    setSubscriptions: (state, action) => {
      state.subscription = action.payload;
    },
    setShowCancelModal: (state, action) => {
      state.showCancelModal = action.payload;
    },
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setSavingcard: (state, action) => {
      state.savingCard = action.payload;
    },
    setShowRemoveAccountModal: (state, action) => {
      state.deletingError = null;
      state.showRemoveAccountModal = action.payload;
    },
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload;
    },
    setShowChangeDefaultModal: (state, action) => {
      state.showChangeDefaultModal = action.payload;
    },
    setShowChangeDefaultPaymentsModal: (state, action) => {
      state.showChangeDefaultPaymentsModal = action.payload;
    },
    setShowTerms: (state, action) => {
      state.showTerms = action.payload;
    },
    setSaving: (state, action) => {
      state.saving = action.payload;
    },
  },
  extraReducers: {
    [getAccounts.fulfilled]: (state, action) => {
      state.accounts = action.payload;
      state.loadingAccounts = false;
      return;
    },
    [getAccounts.pending]: (state, action) => {
      state.loadingAccounts = true;
      return;
    },
    [getAccounts.rejected]: (state, action) => {
      state.loadingError = action.payload;
      state.loadingAccounts = false;
      return;
    },
    [getLocations.fulfilled]: (state, action) => {
      let locat = action.payload;
      let planLocation = 0;
      let l = {};
      locat.forEach((location) => {
        if ((!location.deleted)&&(location.status!=='Non Participating')&&(location.status!=='Referral')) {
          planLocation++;
          if (!(location.brandID in l)) l[location.brandID] = 0;
          l[location.brandID]++;
        }
      });
      state.locations = l;
      state.plan.locations = planLocation;
      state.loadingLocations = false;
      return;
    },
    [getLocations.pending]: (state, action) => {
      state.loadingLocations = true;
      return;
    },
    [getLocations.rejected]: (state, action) => {
      state.loadingError = action.payload;
      state.loadingLocations = false;
      return;
    },
    [loadSecrets.pending]: (state, action) => {
      state.loadingSecrets = true;
      return;
    },
    [loadSecrets.rejected]: (state, action) => {
      state.loadingError = action.payload;
      state.loadingSecrets = false;
      return;
    },
    [getSubscription.fulfilled]: (state, action) => {
      if (action.payload) {
        let subs = action.payload;
        if (action.payload.invoices && action.payload.invoices.length > 0) {
          subs.invoices = subs.invoices.map((inv) =>
            Object.assign({ downloading: false, opening: false }, { ...inv })
          );
        }
        state.subscription = subs;
        if (subs.plan && subs.plan == "corporate") {
          state.existingPlan = "corporate";
          state.currentPlan = "corporate";
        } else {
          if (
            subs.stripeSubscription &&
            (subs.stripeSubscription.status == "active" ||
              subs.stripeSubscription.status == "trialing")
          ) {
            state.existingPlan = "standard";
            state.currentPlan = "standard";
          } else {
            state.existingPlan = "starter";
            state.currentPlan = "starter";
          }
        }
      } else {
        const { account } = configureStore().getState();
        const { user } = account;
        let sortedBrands = [];
        if (user && user.brands) {
          sortedBrands = user.brands.sort((a, b) =>
            a.brand > b.brand ? 1 : b.brand > a.brand ? -1 : 0
          );
        } else {
          sortedBrands = [];
        }
        let subscribedBrands = {};
        sortedBrands.forEach((b) => (subscribedBrands[b.id] = { ...b }));
        state.subscription.brands = subscribedBrands;
      }
      state.loadingSubs = false;
      return;
    },
    [getSubscription.pending]: (state, action) => {
      state.loadingSubs = true;
      return;
    },
    [getSubscription.rejected]: (state, action) => {
      state.loadingError = action.payload;
      state.loadingSubs = false;
      return;
    },
    [cancelSubscription.fulfilled]: (state, action) => {
      let dupSubs = action.payload;
      delete dupSubs.id;
      delete dupSubs.stripeSubscription;
      state.subscription = dupSubs;
      state.showCancelModal = false;
      state.cancelled = true;
      state.cancelling = false;
    },
    [cancelSubscription.pending]: (state, action) => {
      state.cancelling = true;
      return;
    },
    [cancelSubscription.rejected]: (state, action) => {
      state.cancelling = false;
    },
    [reactivateSubscription.fulfilled]: (state, action) => {
      let subs = action.payload;
      subs.stripeSubscription = {
        ...subs.stripeSubscription,
        status: "active",
      };
      state.subscription = subs;
      state.updated = true;
      state.updating = false;
    },
    [reactivateSubscription.pending]: (state, action) => {
      state.updating = true;
    },
    [reactivateSubscription.rejected]: (state, action) => {
      state.updating = false;
      return;
    },
    [removeAccount.fulfilled]: (state, action) => {
      state.deleting = false;
      state.accounts = state.accounts.filter(
        (a) => a.id !== state.selectedAccount.id
      );
      state.selectedAccount = null;
      state.showRemoveAccountModal = false;
      return;
    },
    [removeAccount.pending]: (state, action) => {
      state.deleting = true;
      state.deletingError = null;
      return;
    },
    [removeAccount.rejected]: (state, action) => {
      state.deletingError = action.payload;
      state.deleting = false;
      return;
    },
  },
});

export const {
  setLoading,
  setLoadingSecrets,
  setLoadingError,
  setStripe,
  setElements,
  setPlaidLinkToken,
  setUpdated,
  setSubscriptions,
  setShowCancelModal,
  setAccounts,
  setSavingcard,
  setShowRemoveAccountModal,
  setSelectedAccount,
  setShowChangeDefaultModal,
  setShowChangeDefaultPaymentsModal,
  setSaving,
  setShowTerms
} = settingAccountSlice.actions;
export default settingAccountSlice.reducer;
