import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDocument, getCollection, api } from "../config";
import {donationStatus, convertDateTZ, constants} from "../../services/utilities";
import moment from "moment";
import numeral from "numeral";

export const loadRequest = createAsyncThunk(
    "donation/loadRequest",
    async (payload, { rejectWithValue }) => {
        try{
            let rez = await getDocument("donations", payload, [
                {
                  key: "businessUserID",
                  collection: "users_public",
                  field: "businessUserPublic",
                },
                {
                  key: "userID",
                  collection: "users",
                  field: "user",
                },
                {
                  key: "orgID",
                  collection: "user_organizations",
                  field: "org",
                },
                {
                  key: "donationTypeID",
                  collection: "donation_types",
                  field: "donationType",
                },
                {
                  key: "locationID",
                  collection: "locations",
                  field: "location",
                },
                {
                  key: "brandID",
                  collection: "brands",
                  field: "brand",
                },
            ]);
            return rez;
        }catch(e){
            return rejectWithValue(e.message);
        }
    }
);

export const loadMessages = createAsyncThunk(
  "donation/loadMessages",
  async (payload, { getState, rejectWithValue }) => {
      try{
        let rs = await getCollection("donation_messages", {
          where: [
            ["donationID", "==", payload]
          ],
          order:['date','desc']
        });
        return rs;
      }catch(e){
          return rejectWithValue(e.message);
      }
  }
);

export const changeStatus = createAsyncThunk(
  "donation/changeStatus",
  async (payload, { getState, rejectWithValue }) => {
      try{
        const {account,donation} = getState();
        const {status,reason,date,time,donationValue,recipientDetails,internalDetails,contacts} = payload;
        
        let data = {
          donationID:donation.request.id,
          ['updateBy']:account.user.parentUserID?account.user.parentUserID:account.user.uid
        };

        if((status==='cancelled'||status==='denied')){
          data['reason']=reason||'';
        }

        if(status==='approved'){
          data['dueDate']=moment(date).format(constants.dayFormat);
          if(time&&time.toString().length) data['dueTime']=moment(time).format('LLLL');
          data['dueUnix']=convertDateTZ(moment(moment(date).format('LL') + ' ' + (time&&time.toString().length>0?moment(time).format('HH:00'):' 00:00')),donation.request.location.timezone||moment.tz.guess()).unix();
          data['donationValue']=donationValue||'';
          data['recipientDetails']=recipientDetails||'';
          data['internalDetails']=internalDetails||'';
          data['contacts']=contacts||[];
        }

        switch(status){
          case'cancelled':
            await api.donationCancel(data);
          break;
          case'denied':
            await api.donationDeny(data);
          break;
          case'approved':
            await api.donationApprove(data);
          break;
        }

        return {
          ...data,
          status : {[status]:true},
          [status+'By']:account.user.parentUserID?account.user.parentUserID:account.user.uid
        };
      }catch(e){
          return rejectWithValue(e.message);
      }
  }
);

export const donationSlice = createSlice({
  name: "donation",
  initialState: {
    loading:true,
    loadingError:null,
    loadingMessages:true,
    messagesError:null,
    request:{
        donationType:null
    },
    activeModal:null,
    changeStatusText:null,
    changeStatusError:null,
    changingStatus:false
  },
  reducers: {
    setActiveModal: (state,action) => {
      state.activeModal = action.payload;
    },
    setChangeStatusText: (state,action) => {
      state.changeStatusText = action.payload;
    },
    setChangeStatusError: (state,action) => {
      state.changeStatusError = action.payload;
    },
    setMessages: (state,action) => {
      state.messages = action.payload;
    }
  },
  extraReducers: {
    [loadRequest.fulfilled]: (state, action) => {
        if(action.payload) {
            state.request = {
                ...action.payload,
                fulfillDate: action.payload.fulfillDate ? moment(action.payload.fulfillDate).format('ddd, MMM D, YYYY') : null,
                fulfillDetails: action.payload.fulfillDetails || null,
                dueDate: action.payload.dueDate ? moment(action.payload.dueDate,constants.dayFormat).format('ddd, MMM D, YYYY') + (action.payload.dueTime ? ' ' + moment(action.payload.dueTime).format('h:mm A') : '') : '',
                donationValue: action.payload.donationValue?numeral(action.payload.donationValue).format("$0,0.00"):'',
                filterStatus:donationStatus(action.payload)
            };
        } else {
            state.loadingError = "Request does not exist."
        }
        state.loading = false;
        return;
    },
    [loadRequest.pending]: (state, action) => {
        state.loading = true;
        return;
    },
    [loadRequest.rejected]: (state, action) => {
        state.loadingError = action.payload;
        state.loading = false;
        return;
    },
    [loadMessages.fulfilled]: (state, action) => {
        state.messages = action.payload;
        state.loadingMessages=false;
        return;
      },
      [loadMessages.pending]: (state, action) => {
        state.messagesError = null;
        state.loadingMessages=true;
        return;
      },
      [loadMessages.rejected]: (state, action) => {
        state.messagesError = action.payload;
        state.loadingMessages=false;
        return;
      },
      [changeStatus.fulfilled]: (state, action) => {
        state.request = {
          ...state.request,
          ...action.payload
        };
        state.request.dueDate = action.payload.dueDate ? moment(action.payload.dueDate,constants.dayFormat).format('ddd, MMM D, YYYY') + (action.payload.dueTime ? ' ' + moment(action.payload.dueTime).format('h:mm A') : '') : '';
        state.request.donationValue = action.payload.donationValue?numeral(action.payload.donationValue).format("$0,0.00"):'';
        state.request.filterStatus = donationStatus({...state.request});
        state.changingStatus = false;
        state.activeModal = null;
        return;
      },
      [changeStatus.pending]: (state, action) => {
        state.changeStatusError = null;
        state.changingStatus = true;
        return;
      },
      [changeStatus.rejected]: (state, action) => {
        state.changeStatusError = action.payload;
        state.changingStatus = false;
        return;
      },
  },
});

export const {
    setActiveModal,
    setChangeStatusError,
    setChangeStatusText,
    setMessages
} = donationSlice.actions;
export default donationSlice.reducer;
